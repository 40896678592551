<template>
	<Button type="info" @click="modal = true" v-if="IsLogin">
		<Icon type="md-cart"></Icon>
		购物车 <Tag color="error">{{GetCarLen}}</Tag>
	</Button>
	<Modal v-model="modal" :loading="true" title="购物车">
		<List header="商品列表" :footer="total" border>
			<ListItem v-for="item,key in carslist" :key="key">
				<img :src="item.thumb" alt="" width="30">&nbsp;
				{{item.platform}}&nbsp;&nbsp;有效期{{item.duration}}天&nbsp;&nbsp;<span color="success" v-if="item.extraDuration > 0">赠送{{item.extraDuration}}天,共{{ Number(item.duration) + Number(item.extraDuration) }}天</span>&nbsp;&nbsp;{{item.price}} 元
			</ListItem>
		</List>
		<template #footer>
			<Button type="primary" @click="pay" :loading="loading">
				去支付
			</Button>
			<Button @click="clear">
				清空购物车
			</Button>
		</template>
	</Modal>
</template>

<script>
	export default {
		data() {
			return {
				modal: false,
				loading: false
			}
		},
		methods: {
			clear() {
				this.$store.commit('ClearCars');
			},
			unittostr(unit) {
				let arr = {
					MONTH: '月',
					QUARTER: '季',
					YEAR: '年'
				}
				return arr[unit];
			},
			pay() {
				if(this.$store.getters.Allpay != 0){
					this.$axios.post('/center/order', {"cars": this.carslist,"type":"platform"}).then((response) => {
						this.$Message.success(response.data.msg)
						if(response.data.code == 200){
							this.modal = false;
							this.$store.commit('ClearCars')
							this.$router.push(`/payment/${response.data.orderid}`)
						}
					})
				}else{
					this.$Message.success('购物车为空,请先添加商品');
				}
			},
		},
		computed: {
			IsLogin() {
				if (this.$store.state.jwt != '') {
					return true;
				} else {
					return false;
				}
			},
			carslist() {
				return this.$store.state.cars;
			},
			total() {
				return `总价:${this.$store.getters.total}元`;
			},
			GetCarLen() {
				return Object.keys(this.$store.state.cars).length;
			}
		}
	}
</script>

<style>
</style>