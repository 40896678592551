<template>
	<Button :type="color" long @click="modal = true">
		{{text}}
	</Button>
	<Modal v-model="modal" :loading="true" title="订阅">
		<List header="" footer="" border>
			<template #header>
				<img :src="pintai.thumbheng" style="height: 37px;" />
			</template>
			<ListItem>
				{{pintai.description}}
			</ListItem>
			<ListItem v-if="pintai.infos && pintai.infos.length > 0">
				<ul style="margin-left: 20px;">
					<li v-for="(info,index) in pintai.infos" :key="index" >{{info}}</li>
				</ul>
			</ListItem>
			<ListItem>
				<RadioGroup v-model="plan" vertical>
					<Radio v-for="(item,index) in pintai.plans" :key="index" :label="item">
						<span>有效期 {{item.duration}}天 {{item.price}}元</span> <Tag color="success" v-if="item.extraDuration > 0">赠送{{item.extraDuration}}天,共{{ Number(item.duration) + Number(item.extraDuration) }}天</Tag>    <Tag color="warning" v-if="item.desc"><strong>-{{item.desc}} off</strong></Tag>
					</Radio>
				</RadioGroup>
			</ListItem>
		</List>
		<template #footer>
			<Button type="primary" @click="xvfei" :loading="payloading">
				去支付
			</Button>
			<Button @click="add_to_cars(pintai)">
				加入购物车
			</Button>
		</template>
	</Modal>
</template>

<script>
	export default {
		props:{
			color: String,
			text: String,
			platform: String
		},
		data() {
			return {
				modal: false,
				payloading: false,
				pintai:{},
				plan:{}
			}
		},
		methods: {
			add_to_cars(item) {
				if (this.IsLogin != true) {
					this.$Message.error({content:"请先登录，再进行操作"});
				} else {
					let key = `car-platform-${item.name}`;
					if (key in this.$store.state.cars) {
						this.$Message.error({content:"该平台已经在购物车了"});
					} else {
						let data = { thumb: item.thumb,platform: item.name,per: item.perlimit }
						let product = {...data,...this.plan}
						this.$store.commit('AddCars', {
							'key': key,
							'product': product
						})
						this.$Message.success({content:"添加成功"});
						this.modal = false;
					}
				}
			},
			xvfei(){
				this.payloading = true;
				let data = {"platform": this.platform}
				let product = {...data,...this.plan}
				this.$axios.post('/center/xvfei', product).then((response) => {
					this.$Message.success(response.data.msg)
					if(response.data.code == 200){
						this.$router.push(`/payment/${response.data.orderid}`)
					}
				}).finally(()=>{
					this.modal = false;
					this.payloading = false;
				})
			}
		},
		computed: {
			IsLogin() {
				if(this.$store.state.jwt != ''){
					return true;
				}else{
					return false;
				}
			}
		},
		created() {
			this.$axios.post('/index/platform',{platform:this.platform}).then((resp)=>{
				let data = resp.data
				if(data.infos && data.infos != 'null'){
					data.infos = JSON.parse(data.infos)
				}else{
					data.infos = []
				}
				if(data.plans && data.plans != 'null'){
					data.plans = JSON.parse(data.plans)
					this.plan = data.plans[0];
				}else{
					data.plans = []
				}
				this.pintai = data
			})
		}
	}
</script>

<style>
</style>