import {
	createStore
} from 'vuex'
import Cookies from 'js-cookie'

export default createStore({
	state: {
		jwt: Cookies.get('WNJWT') ? Cookies.get('WNJWT') : false,
		langue: ['en','zh'].includes(Cookies.get('langue')) ? Cookies.get('langue') : null,
		WM518SESSID: '',
		cars:{},
		config: {}
	},
	getters: {
		profile(state){
			if(state.jwt){
				return JSON.parse(atob(state.jwt.split('.')[1])).data
			}else{
				return {};
			}
		},
		total(state){
			let allpay = 0
			for(let i in state.cars){
					allpay += Number(state.cars[i].price);
			}
			return allpay;
		},
		ssid(state){
			return state.WM518SESSID;
		},
		getConfig(state){
			return state.config;
		},
		langue(state){
			return state.langue
		}
	},
	mutations: {
		SetUser(state,param){
			let domain = location.host.indexOf('localhost') != -1 ? 'localhost' : location.host.substr(location.host.indexOf('.'))
			state.jwt = param,Cookies.set('WNJWT',param,{domain:domain,expires: 30 });
		},
		Logout(state){
			state.jwt = false,Cookies.remove('WNJWT')
		},
		Ssid(state,param){
			state.WM518SESSID = param
		},
		AddCars(state,param){
			console.log(state.cars,param)
			state.cars[param.key] = param.product
			console.log(state.cars)
		},
		DelCars(state,key){
			delete state.cars[key];
		},
		ClearCars(state){
			state.cars = {}
		},
		Config(state,config){
			state.config = config
		},
		SetToken(state,param){
			let domain = location.host.indexOf('localhost') != -1 ? 'localhost' : location.host.substr(location.host.indexOf('.'))
			state.token = param,Cookies.set('token',param,{domain:domain});
		},
		SetLangue(state,param){
			let domain = location.host.indexOf('localhost') != -1 ? 'localhost' : location.host.substr(location.host.indexOf('.'))   
			state.langue = param,Cookies.set('langue',param,{domain:domain,expires: 365});
		}
	},
	actions: {
	},
	modules: {}
})
